import { useMoreSpotlights } from '@hooks/useSpotlightExperiment';
import { useAppSelector } from '@redux/hooks';
import { compose } from '@xo-union/react-css-modules';
import { Column, Row } from '@xo-union/tk-component-grid';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { Fragment } from 'react';
import { splitResults } from '../../../../components/ListingsSectionsResults';
import { selectShowBuzzBadges } from '../../../../utils';
import BuzzBadgeSection from '../BuzzBadgeSection';
import Result from '../Result';
import Styles from '../styles.scss';
import { getSectionIndex, shouldRenderBuzzBadges } from '../utils/buzzBadges';

export const ListingsSectionsGrid = () => {
	const category = useAppSelector((state) => state.category);
	const currentPage = useAppSelector((state) => state.search.pagination.page);
	const marketCode = useAppSelector((state) => state.settings.marketCode) || '';
	const maxResultColumns = useAppSelector(
		(state) => state.search.maxResultColumns,
	);
	const showBuzzBadges = useAppSelector((state) => selectShowBuzzBadges(state));
	const vendors = useAppSelector((state) => state.search.vendors);
	const [hasMoreSpotlights] = useMoreSpotlights({
		categoryCode: category.code,
		marketCode,
	});

	if (vendors.length === 0) {
		return null;
	}
	const { paid, unpaid } = splitResults(vendors);

	return (
		<Row vGutters classes={compose({ row: Styles.rowContainer })}>
			{paid.map((vendor, index) => (
				<Fragment key={vendor.id}>
					<Result
						impressionType="Directory: Main"
						index={index}
						vendor={vendor}
						vendorsLength={vendors.length}
					/>
					{shouldRenderBuzzBadges(
						showBuzzBadges,
						index,
						maxResultColumns,
						hasMoreSpotlights,
					) && (
						<BuzzBadgeSection
							categoryCode={category.code}
							currentPage={currentPage}
							hasMoreSpotlights={hasMoreSpotlights}
							marketCode={marketCode}
							sectionIndex={getSectionIndex(
								index,
								maxResultColumns,
								hasMoreSpotlights,
							)}
						/>
					)}
				</Fragment>
			))}
			{paid.length !== 0 && unpaid.length !== 0 && (
				<Column xs="12">
					<Body1 bold className={Styles.listingsSectionsSubHeader}>
						All other results
					</Body1>
				</Column>
			)}
			{unpaid.map((vendor, index) => (
				<Fragment key={vendor.id}>
					<Result
						impressionType="Directory: Main"
						index={index + paid.length}
						vendor={vendor}
						vendorsLength={vendors.length}
					/>
					{shouldRenderBuzzBadges(
						showBuzzBadges,
						index + paid.length,
						maxResultColumns,
						hasMoreSpotlights,
					) && (
						<BuzzBadgeSection
							categoryCode={category.code}
							currentPage={currentPage}
							hasMoreSpotlights={hasMoreSpotlights}
							marketCode={marketCode}
							sectionIndex={getSectionIndex(
								index + paid.length,
								maxResultColumns,
								hasMoreSpotlights,
							)}
						/>
					)}
				</Fragment>
			))}
		</Row>
	);
};
