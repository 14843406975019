import { seoFilter } from '../../../helpers/seo';
import categoryCodeToImageMap from '../../utils/categoryMediaMappings';
import {
	getSeoTextVariantCatalogTitle,
	isRenameCatalogTitleExperiment,
} from './utils/renameCatalogTitleExperiment';

export const ogImage = (
	categoryCode: keyof typeof categoryCodeToImageMap,
): string =>
	`https://media-api.xogrp.com/images/${categoryCodeToImageMap[categoryCode].id}`;

export const categoryText = (
	category: Redux.Category,
	categoryFilters: Filters.CategoryFilters,
): string =>
	seoFilter(category.code, categoryFilters, true) || category.plural.term;

export const pageTitle = (location: Redux.Location, seoCategory: string) => {
	if (isRenameCatalogTitleExperiment(location)) {
		return getSeoTextVariantCatalogTitle(seoCategory, location);
	}

	const locationString = `${location.city}, ${location.stateCode}`;

	return `${seoCategory} in ${locationString}`;
};
