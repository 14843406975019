import { selectAssignment } from '@redux/experiments/selectors/index';
import type { SpotlightAdAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const spotlightAdSelector = (state: State) => {
	if (state.viewport.isMobile) {
		return null;
	}

	return selectAssignment(state, ['spotlightAd']) as SpotlightAdAssignment;
};

export { spotlightAdSelector };
