import { isSemanticSearchCalledAtom } from '@components/listings-search-bar/atoms';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import type { CategoryCode } from 'types/Category';

export type TrackingActions =
	| 'open_category_menu'
	| 'select category'
	| 'open_geography_menu'
	| 'typing'
	| 'location_selected'
	| 'search_completed';

export type SourcePage = 'category results' | 'semantic_results';

export const SHARED_PAYLOAD = {
	event: 'Vendor Search Interaction',
	properties: {
		product: 'marketplace',
	},
} as const;

export const INVALID_PAYLOAD = {
	event: 'Invalid event',
	properties: {},
} as const;

export const trackOpenCategoryMenu = (sourcePage: SourcePage) => {
	return {
		...SHARED_PAYLOAD,
		properties: {
			...SHARED_PAYLOAD.properties,
			action: 'open_category_menu',
			sourcePage,
			sourceContent: 'category_choice',
		} as const,
	};
};

export const trackSelectCategory = (
	sourcePage: SourcePage,
	categoryCode?: CategoryCode,
) => {
	if (!categoryCode) {
		return INVALID_PAYLOAD;
	}

	return {
		...SHARED_PAYLOAD,
		properties: {
			...SHARED_PAYLOAD.properties,
			action: 'select category',
			sourcePage,
			sourceContent: 'category_choice',
			vendorCategoryCode: categoryCode,
		},
	} as const;
};

export const trackOpenGeographyMenu = (sourcePage: SourcePage) => {
	return {
		...SHARED_PAYLOAD,
		properties: {
			...SHARED_PAYLOAD.properties,
			action: 'open_geography_menu',
			sourcePage,
			sourceContent: 'search_geography',
		},
	} as const;
};

export const trackGeoTyping = (
	sourcePage: SourcePage,
	searchString?: string,
) => {
	if (!searchString) {
		return INVALID_PAYLOAD;
	}

	return {
		...SHARED_PAYLOAD,
		properties: {
			...SHARED_PAYLOAD.properties,
			action: 'typing',
			sourcePage,
			sourceContent: 'search_geography',
			searchString,
		},
	};
};

export const trackLocationSelected = (
	sourcePage: SourcePage,
	vendorMarket?: string,
) => {
	if (!vendorMarket) {
		return INVALID_PAYLOAD;
	}

	return {
		...SHARED_PAYLOAD,
		properties: {
			...SHARED_PAYLOAD.properties,
			action: 'location_selected',
			sourcePage,
			sourceContent: 'search_geography',
			vendorMarket,
		},
	};
};

export const trackSearchCompleted = (sourcePage: SourcePage) => {
	return {
		...SHARED_PAYLOAD,
		properties: {
			...SHARED_PAYLOAD.properties,
			action: 'search_completed',
			sourcePage,
			sourceContent: 'search_geography',
		},
	};
};

export type OptionalArgs =
	| { categoryCode: CategoryCode; searchString?: never; vendorMarket?: never }
	| { categoryCode?: never; searchString: string; vendorMarket?: never }
	| { categoryCode?: never; searchString?: never; vendorMarket: string };

export const useTrackCatGeo = () => {
	const { track } = useAnalyticsContext();
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);

	return useCallback(
		(action: TrackingActions, opts?: OptionalArgs) => {
			const sourcePage = isSemanticSearchCalled
				? 'semantic_results'
				: 'category results';
			const trackFunctions = {
				open_category_menu: () => trackOpenCategoryMenu(sourcePage),
				'select category': () =>
					trackSelectCategory(sourcePage, opts?.categoryCode),
				open_geography_menu: () => trackOpenGeographyMenu(sourcePage),
				typing: () => trackGeoTyping(sourcePage, opts?.searchString),
				location_selected: () =>
					trackLocationSelected(sourcePage, opts?.vendorMarket),
				search_completed: () => trackSearchCompleted(sourcePage),
			};

			if (trackFunctions[action]) {
				return track({
					...trackFunctions[action](),
				});
			}
		},
		[track, isSemanticSearchCalled],
	);
};
