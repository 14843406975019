import FooterCategories from '@components/footer_categories';
import {
	fieldValueAtom,
	isSemanticSearchCalledAtom,
	updateIsSemanticSearchCalled,
} from '@components/listings-search-bar/atoms';
import { useSemanticSearch } from '@components/listings-search-bar/hooks/use-semantic-search';
import Breadcrumbs from '@components/shared/Breadcrumbs';
import { useListingsFeatures } from '@feature-flags/hooks/VendorsSearch/use-listings-features';
import { mobileMapViewAssignmentSelector } from '@redux/experiments/selectors/mobile-map-view';
import { isInQuickFiltersExperiment } from '@redux/experiments/selectors/quick-filters';
import { useAppSelector } from '@redux/hooks';
import { getConversations as getConversationsThunk } from '@redux/messagedVendors/thunks';
import { setIsReferred as setIsReferredAction } from '@redux/settings';
import { requestAnonymousId } from '@redux/settings/actions';
import type { CategoryCode } from '@typings/Category';
import type { CategoryFilters } from '@typings/filters';
import type { AppliedFilters } from '@typings/filters';
import { SORT_DIRECTIONS } from '@utils/sortParam';
import { compose as composeCSS } from '@xo-union/react-css-modules';
import { Row, TopLevelContainer } from '@xo-union/tk-component-grid';
import { CookieStorage } from 'cookie-storage';
import { useAtomValue, useSetAtom } from 'jotai';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import React, { type FC, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import type { SortType } from 'types/search';
import { buildRangeFilters } from '../../../../api/utils';
import { XO_SESSION_TOKEN } from '../../../../constants/membership';
import { updateCategoryFilter } from '../actions/filters';
import { changeLocation } from '../actions/location';
import { fetchSearchResults, updatePage, updateSort } from '../actions/search';
import DynamicFAQ from '../components/DynamicFAQ';
import PageDescription from '../components/PageDescription';
import { SponsoredResultsDisclaimer } from '../components/SponsoredResultsDisclaimer';
import { VenueFacetLinks } from '../components/VenueFacetLinks';
import * as utils from '../utils';
import { getValidFilters } from '../utils';
import FilterPills from './FilterPills';
import { QuickFilters } from './QuickFilters/components/QuickFilters';
import { SemanticSearchResults } from './SemanticSearch/components/SemanticSearchResults';
import TopBar from './TopBar';
import Styles from './WrappedVendorsSearch.scss';

const isMapViewSelected = (state: Redux.State) =>
	mobileMapViewAssignmentSelector(state) === 'map-toolbar';

const getSessionToken = () => {
	const cookieStorage = new CookieStorage();
	return cookieStorage.getItem(XO_SESSION_TOKEN);
};

interface Location {
	hash: string;
	pathname: string;
	search: string;
	state?: string;
}

export interface Params {
	categorySlug: string;
	facet?: string;
	locationSlug: string;
	term?: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;

interface DispatchProps {
	changeLocation: (location: { city: string; stateCode: string }) => void;
	fetchSearchResults: (opts: unknown) => void;
	getConversations: (memberId: string, sessionToken: string) => void;
	isReferred: (value: boolean) => void;
	requestAnonymousId: () => void;
	updatePage: (page: number) => void;
	updateCategoryFilter: (filters: CategoryFilters) => void;
	updateSort: (sort: SortType | string) => void;
}

type WrappedVendorsSearchProps = StateProps & DispatchProps;

interface Previous {
	appliedFilters: AppliedFilters;
	categoryCode: CategoryCode;
	city: string;
	location: Location;
	marketCode?: string;
	stateCode: string;
}

function usePrevious(value: Previous) {
	const ref = useRef<Previous>();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
}

const WrappedVendorsSearch: FC<WrappedVendorsSearchProps> = (props) => {
	const {
		applied,
		appliedFilters,
		categories,
		categoryCode,
		categoryGuid,
		changeLocation,
		city,
		fetchSearchResults,
		getConversations,
		isReferred,
		marketCode,
		member,
		page,
		searchFilterMap,
		showMapView,
		stateCode,
		updateCategoryFilter,
		updatePage,
		updateSort,
	} = props;

	const location: Location = useLocation();
	const params: Params = useParams();
	const setFieldValue = useSetAtom(fieldValueAtom);
	const setIsSemanticSearchCalled = useSetAtom(updateIsSemanticSearchCalled);
	const { handleSemanticSearch } = useSemanticSearch();

	useEffect(() => {
		requestAnonymousId();
		isReferred(true);
		if (member?.id) {
			getConversations(member.id, getSessionToken() || '');
		}
	}, [getConversations, isReferred, member]);

	const prevProps = usePrevious({
		appliedFilters,
		categoryCode,
		city: city || '',
		location,
		marketCode: marketCode,
		stateCode: stateCode || '',
	});

	useEffect(() => {
		if (prevProps) {
			const locationChanged =
				location.pathname !== prevProps.location.pathname ||
				location.state !== prevProps.location.state ||
				city !== prevProps.city ||
				stateCode !== prevProps.stateCode ||
				marketCode !== prevProps.marketCode;
			const categoryChanged = categoryCode !== prevProps.categoryCode;
			const appliedFiltersChanged = !isEqual(
				appliedFilters,
				prevProps.appliedFilters,
			);
			const searchChanged = location.search !== prevProps.location.search;
			const shouldUpdate =
				searchChanged ||
				appliedFiltersChanged ||
				locationChanged ||
				categoryChanged;
			const shouldFetchSearch = searchChanged;

			if (shouldUpdate) {
				syncPage(shouldFetchSearch);
			}
		}
	}, [
		appliedFilters,
		categoryCode,
		city,
		location,
		marketCode,
		prevProps,
		stateCode,
	]);

	useEffect(() => {
		if (member?.id) {
			getConversations(member.id, getSessionToken() || '');
		}
	}, [getConversations, member]);

	const getCategoryFilters = (filterArr: unknown) => {
		const initialCategories = utils.getEmptyFilter(categories);

		return utils.buildCategoryFilters(
			filterArr,
			searchFilterMap,
			initialCategories,
		);
	};

	const buildCategoryFilters = () => {
		const {
			page = 1,
			sort,
			gatekeeper,
			...restFilters
		} = queryString.parse(location.search);
		const filterArr = utils.splitMultipleFilters(restFilters);

		if (params?.facet) {
			return {
				filters: utils.getAllFilterIds(appliedFilters),
				categoryFilters: {
					...appliedFilters,
				},
				page,
				sort,
				gatekeeper,
			};
		}

		const filters = getValidFilters(filterArr, searchFilterMap);
		const categoryFilters = getCategoryFilters(filterArr);

		return {
			filters,
			categoryFilters,
			page,
			sort,
			gatekeeper,
		};
	};

	/**
	 * On url change we will
	 * 1. re-fetch search results
	 * 2. update location, pagination & filter panel
	 *
	 * if we see a facet we will remember the previous filters
	 * else we will derive our filters only from reading the url
	 *
	 */
	const syncPage = (shouldFetchSearch: boolean) => {
		const userLocation = utils.getLocationFromSlug(
			params.locationSlug || 'new-york-ny',
		);
		const { categoryFilters, filters, page, sort } = buildCategoryFilters();

		syncPageCount(Number(page));
		syncFilters(categoryFilters);
		syncLocation(userLocation);
		if (typeof sort === 'string') {
			syncSort(sort);
		}

		if (shouldFetchSearch) {
			const rangeFilters = buildRangeFilters(applied);
			fetchSearchResults({
				categoryCode,
				categoryGuid,
				filters: [categoryGuid, ...filters],
				location: userLocation,
				page: Number(page),
				rangeFilters,
				sort,
			});
		}
	};

	const syncFilters = (categoryFilters: CategoryFilters) => {
		return updateCategoryFilter(categoryFilters);
	};

	const syncLocation = (location: { city: string; stateCode: string }) => {
		if (location.city !== city || location.stateCode !== stateCode) {
			changeLocation(location);
		}
	};

	const syncPageCount = (newPage: number) => {
		if (page !== newPage) {
			updatePage(newPage);
		}
	};

	const syncSort = (sort: SortType | string) => {
		return updateSort(sort || SORT_DIRECTIONS.recommended.type);
	};

	useListingsFeatures();

	useEffect(() => {
		if (params.term) {
			const searchTerm = params.term.split('-').join(' ');

			setFieldValue(searchTerm);
			handleSemanticSearch(searchTerm);
			setIsSemanticSearchCalled(true);
		}
	}, [
		handleSemanticSearch,
		params.term,
		setIsSemanticSearchCalled,
		setFieldValue,
	]);

	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const hideFilterPills = isSemanticSearchCalled;
	const isInQuickFilters = useAppSelector(isInQuickFiltersExperiment);

	return (
		<TopLevelContainer
			classes={composeCSS({
				'top-level-container': Styles.vendorSearchContainer, // Look to deprecate this, as it is not suggested to use it
			})}
		>
			{isInQuickFilters ? <QuickFilters /> : null}
			<TopBar />
			<div className={Styles.filterPills}>
				{!hideFilterPills && (
					<FilterPills
						match={{
							params: {
								facet: params.facet || '',
								locationSlug: params.locationSlug || 'new-york-ny',
							},
						}}
						searchLocation={location.search}
						showMapView={showMapView}
					/>
				)}
				<SponsoredResultsDisclaimer />
			</div>
			<SemanticSearchResults />
			<VenueFacetLinks />
			<FooterCategories city={city || ''} stateCode={stateCode || ''} />
			<Row>
				<div className={Styles.faqAndPageWrapper} />
			</Row>
			<DynamicFAQ />
			<PageDescription />
			<Breadcrumbs
				className={Styles.footerBreadcrumb}
				categoryCode={categoryCode}
				city={city}
				stateCode={stateCode}
			/>
		</TopLevelContainer>
	);
};

export function mapStateToProps(state: Redux.State) {
	const {
		category: { id, code },
		filters: { categories, appliedFilters },
		location: { city, stateCode },
		search: {
			filters,
			filterPills: { applied },
			pagination: { page },
		},
		settings: { marketCode },
	} = state;

	return {
		applied,
		appliedFilters,
		categories,
		categoryGuid: id,
		categoryCode: code,
		city,
		marketCode,
		member: state.membership.member,
		page,
		searchFilterMap: utils.memoizedSearchFiltersToMap(filters.filters),
		stateCode,
		showMapView: isMapViewSelected(state),
	};
}

export const mapDispatchToProps = {
	changeLocation,
	fetchSearchResults,
	getConversations: getConversationsThunk,
	isReferred: setIsReferredAction,
	requestAnonymousId,
	updatePage,
	updateCategoryFilter,
	updateSort,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
);

export default enhance(WrappedVendorsSearch);
