import { selectAssignment } from '@redux/experiments/selectors/index';
import type { LearnToRankAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

export const learnToRankSelector = (state: State) => {
	return selectAssignment(state, ['learnToRank']) as LearnToRankAssignment;
};

export const isLearnToRankSelector = (state: State) => {
	const assignment = learnToRankSelector(state);

	return assignment === 'learn-to-rank';
};

export const isLearnToRankMultipliedSelector = (state: State) => {
	const assignment = learnToRankSelector(state);

	return assignment === 'learn-to-rank-multiplied';
};
