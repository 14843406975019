import { useAtomValue } from 'jotai';
import React from 'react';
import { desiredVenueSettingAtom, guestCapacityAtom } from '../atoms';
import { useQuickFilters } from '../hooks/use-quick-filters';

export const QuickFilters = () => {
	useQuickFilters();
	const desiredVenueSetting = useAtomValue(desiredVenueSettingAtom);
	const guestCapacity = useAtomValue(guestCapacityAtom);

	return (
		<div style={{ border: '5px solid black' }}>
			<div style={{ border: '1px solid red', paddingBottom: '16px' }}>
				<h2>Desired Venue Settings</h2>
				{desiredVenueSetting.map((d) => (
					<div key={d}>{d}</div>
				))}
			</div>
			<div style={{ border: '1px solid blue' }}>
				<h2>Guest Capacity</h2>
				<div>{guestCapacity}</div>
			</div>
		</div>
	);
};
