import { CATALOG_TITLE_EXPERIMENT_VARIANT_MAP } from '@constants/experiments';

export function isRenameCatalogTitleExperiment(
	location: Redux.Location,
): 'A' | 'B' | null {
	if (!location.stateCode) return null;

	const stateCode = location.stateCode.toLowerCase();
	if (CATALOG_TITLE_EXPERIMENT_VARIANT_MAP.a.includes(stateCode)) return 'A';
	if (CATALOG_TITLE_EXPERIMENT_VARIANT_MAP.b.includes(stateCode)) return 'B';

	return null;
}

export function getSeoTextVariantCatalogTitle(
	vendorCategory: string,
	location: Redux.Location,
): string {
	const experimentVariant = isRenameCatalogTitleExperiment(location);

	if (experimentVariant) {
		return experimentVariant === 'A'
			? `${vendorCategory} in ${location.city}, ${location.stateCode} With Prices`
			: `${vendorCategory} With Prices in ${location.city}, ${location.stateCode}`;
	}

	return '';
}
