import { ListingsSearchBar } from '@components/listings-search-bar/listings-search-bar';
import React from 'react';
import { SemanticControls } from './SemanticControls';
import Styles from './SemanticSearch.styles.scss';

export const SemanticSearch = () => {
	return (
		<div className={Styles.semanticSearchWrapper}>
			<SemanticControls />
			<ListingsSearchBar />
		</div>
	);
};
