import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { reduxAtom } from '../../../../jotai/redux-atoms';

interface QuickFiltersState {
	desiredVenueSetting: string[];
}

export const quickFiltersInitialState: QuickFiltersState = {
	desiredVenueSetting: [],
};

export const quickFiltersAtom = atom<QuickFiltersState>(
	quickFiltersInitialState,
);

export const guestCapacityAtomConfig = atom((get) => {
	const state = get(reduxAtom);
	return state.membership.member?.number_of_guests;
});

export const guestCapacityAtom = atomWithDefault((get) => {
	const guestCap = get(guestCapacityAtomConfig);
	return guestCap;
});

export const desiredVenueSettingAtom = atom(
	(get) => get(quickFiltersAtom).desiredVenueSetting,
	(get, set, desiredVenueSetting: string[]) => {
		set(quickFiltersAtom, {
			...get(quickFiltersAtom),
			desiredVenueSetting,
		});
	},
);
