import { getDecisions } from '@api/quickFilters/getDecisions';
import { XO_SESSION_TOKEN } from '@constants/membership';
import { isInQuickFiltersExperiment } from '@redux/experiments/selectors/quick-filters';
import { useAppSelector } from '@redux/hooks';
import { CookieStorage } from 'cookie-storage';
import { useSetAtom } from 'jotai';
import { useContext, useEffect } from 'react';
import WeddingContext from '../../../../../contexts/WeddingsContext/WeddingsContext';
import { desiredVenueSettingAtom } from '../atoms';

export const useQuickFilters = () => {
	const isInQuickFilters = useAppSelector(isInQuickFiltersExperiment);
	const { weddingId } = useContext(WeddingContext);
	const setDesiredVenueSetting = useSetAtom(desiredVenueSettingAtom);

	const cookieStorage = new CookieStorage();
	const sessionToken = cookieStorage.getItem(XO_SESSION_TOKEN) || '';

	useEffect(() => {
		if (!isInQuickFilters || !sessionToken || !weddingId) {
			return;
		}

		const fetchData = async () => {
			const response = await getDecisions({ token: sessionToken, weddingId });
			if (response) {
				const { decisions } = response;
				const desiredVenueSettingDecision = decisions.find(
					(d) => d.id === 'desired_venue_setting',
				);

				if (desiredVenueSettingDecision) {
					setDesiredVenueSetting(desiredVenueSettingDecision.answer);
				}
			}
		};
		fetchData();
	}, [sessionToken, weddingId, isInQuickFilters, setDesiredVenueSetting]);
};
